.error_msg,
.success_msg {
  padding: 15px;
  margin: 5px 0;
  font-size: 14px;
  background-color: #f34646;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.success_msg {
  background-color: #5cdd5c;
}
